body {
  margin: 0;
  font-family: 'Avenir-Light'!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF!important;
  color: #000!important;
  overflow-x: hidden;
}

code {
  font-family: 'Avenir-Light'!important;
}


.topdif {
  display: block;
  position: relative;
  top: -350px;
  visibility: hidden;
}